/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        /*
         * imageLightbox
         */

        var ilbElements = {
            $activity: $('<div id="imagelightbox-loading"><div></div></div>').hide().appendTo('body'),
            $overlay: $('<div id="imagelightbox-overlay"></div>').hide().appendTo('body')
        };

        var ilbHandler = {
            activityIndicatorOn: function() {
                ilbElements.$activity.show().addClass('loading');
            },
            activityIndicatorOff: function() {
                ilbElements.$activity.removeClass('loading').hide();
            },
            overlayOn: function() {
                ilbElements.$overlay.show();
            },
            overlayOff: function() {
                ilbElements.$overlay.hide();
            }
        };

        var ilbConfig = {
            onStart: function() {
                ilbHandler.overlayOn();
            },
            onEnd: function() {
                ilbHandler.overlayOff();
                ilbHandler.activityIndicatorOff();
            },
            onLoadStart: function() {
                ilbHandler.activityIndicatorOn();
            },
            onLoadEnd: function() {
                ilbHandler.activityIndicatorOff();
            }
        };
        $('a.imagelightbox').imageLightbox(ilbConfig);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  if (typeof responsiveNav !== 'undefined') {
    var nav = responsiveNav(".nav-collapse", {
      animate: true,
      label: "Navigation",
      customToggle: 'nav-toggler',
      insert: 'after',
      openPos: 'static',
      navActiveClass: 'js-nav-active'
    });
  }


})(jQuery); // Fully reference jQuery after this point.
